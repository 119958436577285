<script>
import { ArrowUpIcon, HelpCircleIcon } from "vue-feather-icons";
import { Carousel, Slide } from "vue-carousel";

import Navbar from "@/components/navbar0";
import Switcher from "@/components/switcher";
import Footer from "@/components/footer";
import Testimonial from "@/components/testimonial";
import Pricing from "@/components/pricing_rub";
import PricingRub from "@/components/pricing2";

/**
 * Page-pricing component
 */
export default {
  data() {
    return {

      pricingDataMonth: [
        {
          title: "Пробный доступ",
          price: 0,
          period: "мес",
          feature: [
            "100 просмотров резюме",
            "50 писем рассылки",
            "Аналитика по рассылке",
          ],
          button: "Получить",
        },
        {
          title: "Полный Доcтуп к Платформе",
          price: 7990,
          period: "мес",
          feature: [
            "Полный доступ к базе резюме",
            "Неограниченные рассылки",
            "Аналитика по рассылкам",
            "Консультации рекрутера",
          ],
          button: "Купить",
          isBest: true,
        },
        {
          title: "Доcтуп и интеграция",
          price: 9990,
          period: "мес",
          feature: [
            "Полный доступ к базе резюме",
            "Неограниченные рассылки",
            "Аналитика по рассылкам",
            "Консультации рекрутера",
            "Интеграция с ATS или CRM"
          ],
          button: "Купить",
        },
      ],
      pricingDataYear: [
        {
          title: "Пробный доступ",
          price: 0,
          period: "мес",
          feature: [
            "100 просмотров резюме",
            "50 писем рассылки",
            "Аналитика по рассылке",
          ],
          button: "Получить",
        },
        {
          title: "Полный Доcтуп к Платформе",
          price: 5990,
          period: "мес",
          feature: [
            "Полный доступ к базе резюме",
            "Неограниченные рассылки",
            "Аналитика по рассылкам",
            "Консультации рекрутера",
          ],
          button: "Купить",
          isBest: true,
        },
        {
          title: "Доcтуп и интеграция",
          price: 7490,
          period: "мес",
          feature: [
            "Полный доступ к базе резюме",
            "Неограниченные рассылки",
            "Аналитика по рассылкам",
            "Консультации рекрутера",
            "Интеграция с ATS или CRM"
          ],
          button: "Купить",
        },
      ],



      pricingData: [
        {
          title: "Free",
          price: 0,
          feature: [
            "Full Access",
            "Enhanced Security",
            "Source Files",
            "1 Domain Free",
          ],
          button: "Buy Now",
        },
        {
          title: "Starter",
          price: 39,
          feature: [
            "Full Access",
            "Source Files",
            "Free Appointments",
            "Enhanced Security",
          ],
          button: "Get Started",
          isBest: true,
        },
        {
          title: "Professional",
          price: 59,
          feature: [
            "Full Access",
            "Enhanced Security",
            "Source Files",
            "1 Domain Free",
          ],
          button: "Try it Now",
        },
      ],
      testimonialData: [
        {
          id: 1,
          profile: "images/client/01.jpg",
          message:
            "It seems that only fragments of the original text remain in the Lorem Ipsum texts used today.",
          name: "Thomas Israel",
          designation: "C.E.O",
        },
        {
          id: 2,
          profile: "images/client/02.jpg",
          message:
            "One disadvantage of Lorum Ipsum is that in Latin certain letters appear more frequently than others.",
          name: "Barbara McIntosh",
          designation: "M.D",
        },
        {
          id: 3,
          profile: "images/client/03.jpg",
          message:
            "The most well-known dummy text is the 'Lorem Ipsum', which is said to have originated in the 16th century.",
          name: "Carl Oliver",
          designation: "P.A",
        },
        {
          id: 4,
          profile: "images/client/04.jpg",
          message:
            "According to most sources, Lorum Ipsum can be traced back to a text composed by Cicero.",
          name: "Christa Smith",
          designation: "Manager",
        },
        {
          id: 5,
          profile: "images/client/05.jpg",
          message:
            "There is now an abundance of readable dummy texts. These are usually used when a text is required.",
          name: "Dean Tolle",
          designation: "Developer",
        },
        {
          id: 6,
          profile: "images/client/05.jpg",
          message:
            "Thus, Lorem Ipsum has only limited suitability as a visual filler for German texts.",
          name: "Jill Webb",
          designation: "Designer",
        },
      ],
    };
  },
  components: {
    Navbar,
    Switcher,
    Footer,
    Carousel,
    Slide,
    Pricing,
    PricingRub,
    ArrowUpIcon,
    HelpCircleIcon,
    Testimonial,
  },
};
</script>

<template>
  <div>
    <Navbar />

    <!-- Hero Start -->
    <section class="bg-half bg-light d-table w-100">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12 text-center">
            <div class="page-next-level">
              <h4 class="title">Цены</h4>
              <div class="page-next">
                <nav aria-label="breadcrumb" class="d-inline-block">
                  <ul class="breadcrumb bg-white rounded shadow mb-0">
                    <li class="breadcrumb-item">
                      <router-link to="/">Skillrank</router-link>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                      Цены
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </section>
    <!--end section-->
    <!-- Hero End -->

    <!-- Shape Start -->
    <div class="position-relative">
      <div class="shape overflow-hidden text-white">
        <svg
          viewBox="0 0 2880 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
            fill="currentColor"
          ></path>
        </svg>
      </div>
    </div>
    <!--Shape End-->

    <!-- Price Start -->
    <section class="section">

      <div class="container mt-100 mt-60">
        <div class="row justify-content-center">
          <div class="col-12 text-center">
            <div class="section-title mb-4 pb-2">
              <h4 class="title mb-4">Цены</h4>
              <p class="text-muted para-desc mb-0 mx-auto">
                Оплатите доступ
                <span class="text-primary font-weight-bold">Skillrank</span> .
              </p>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->

        <div class="row align-items-center">
          <div class="col-12 mt-4 pt-2">
            <div class="text-center">
              <b-tabs
                  pills
                  justified
                  nav-class="justify-content-center d-inline-block border py-1 px-2 rounded-pill"
              >
                <b-tab
                    title-item-class="d-inline-block"
                    title-link-class="px-3 rounded-pill"
                >
                  <template v-slot:title> Месяц </template>
                  <PricingRub :pricingData="pricingDataMonth" />
                  <!--end row-->
                </b-tab>
                <b-tab
                    title-item-class="d-inline-block"
                    title-link-class="px-3 rounded-pill"
                >
                  <template v-slot:title>
                    Год
                    <span class="badge badge-pill badge-success">25% скидка </span>
                  </template>
                  <PricingRub :pricingData="pricingDataYear" />

                </b-tab>
              </b-tabs>
            </div>
          </div>

        </div>

        <!--end row-->
      </div>
    </section>

    <!--end section-->
    <Footer />
    <!-- Footer End
    <Switcher />
    -->
    <!-- Back to top -->
    <a
      href="javascript: void(0);"
      class="btn btn-icon btn-primary back-to-top"
      id="back-to-top"
      v-scroll-to="'#topnav'"
    >
      <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
    <!-- Back to top -->
  </div>
</template>
